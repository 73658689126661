import { Tab, TabGroup, TabList, TabPanel, TabPanels } from '@headlessui/react'
import { Fragment } from 'react'
import Code from '@/components/ui/code/code'
import { cx } from '@/utils/strings'
import { TypeComponentCode } from '@/types/ctf'

type CodeTabsProps = {
  code: TypeComponentCode<'WITHOUT_UNRESOLVABLE_LINKS'>[]
}

export default function CodeTabs({ code }: CodeTabsProps) {
  return (
    <div className="w-full">
      <TabGroup>
        <TabList className="flex flex-wrap gap-1 md:gap-4 mb-2">
          {code.map((block) => {
            return (
              <Tab as={Fragment} key={block.sys.id}>
                {({ hover, selected }) => (
                  <button
                    type="button"
                    className={cx(
                      'py-1 px-3 border-2 border-white dark:border-gray-700 rounded-3xl',
                      selected && `bg-tealBlue border-tealBlue dark:border-tealBlue dark:text-gray-800`,
                      hover && `border-tealBlue dark:border-tealBlue`
                    )}
                  >
                    {block.fields.language}
                  </button>
                )}
              </Tab>
            )
          })}
        </TabList>
        <TabPanels>
          {code.map((block) => {
            return (
              <TabPanel
                key={block.sys.id}
                className="w-full [.bw-code-container]:h-[10rem] [.bw-code-container]:text-sm md:[&_.bw-code-container]:h-[clamp(10rem,50vh,38rem)] md:[.bw-code-container]:text-base"
              >
                <Code language={block.fields.language} body={block.fields.body as any} />
              </TabPanel>
            )
          })}
        </TabPanels>
      </TabGroup>
    </div>
  )
}
